//
// _badges.scss
//

// Lighten badge
@mixin badge-variant-light($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);
  box-shadow: none;
}

// Outline badge

@mixin badge-variant-outline($bg) {
  color: $bg;
  border: 1px solid $bg;
  background-color: transparent;
  box-shadow: none;
}

// Lighten badge (soft)

@each $color, $value in $theme-colors {
  .badge-soft-#{$color} {
    @include badge-variant-light($value);
  }
}

// Outline badge

@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-variant-outline($value);
  }
}
