//
// reboot.scss
//
body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  &.loading {
    visibility: hidden;
    opacity: 0;
  }
}

// Forms
label {
  font-weight: $font-weight-bold;
}

button:focus {
  outline: none;
}

.row > * {
  position: relative;
}

a {
  &:focus {
    outline: none;
  }
}
