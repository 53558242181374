//
// topbar.scss
//

@keyframes client-name-animation {
  from {
    left: -60px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

// Logo
.logo {
  display: block;

  .client-name-animation {
    animation-name: client-name-animation;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
  }

  span.logo-lg {
    display: block;

    .client-name {
      position: relative;
      font-size: 2.2em;
      margin-left: 10px;
      font-weight: 500;
      color: $hori-menu-item-color;
      vertical-align: middle;
    }
  }

  span.logo-sm {
    display: none;

    .client-name {
      position: relative;
      font-size: 2em;
      margin-left: 10px;
      margin-right: 10px;
      font-weight: 500;
      color: $hori-menu-item-color;
      vertical-align: middle;
    }
  }

  .logo-lg-text-dark {
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }

  .logo-lg-text-light {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
  transition: all 0.1s ease-out;
  padding: 0 24px;
  background: $bg-topbar-dark;
  border-right: 1px solid $bg-topbar-dark;

  .logo {
    line-height: $topbar-height;
  }
}

.logo-light {
  display: $logo-light-display;
}

.logo-dark {
  display: $logo-dark-display;
}

.navbar-custom {
  background-color: $bg-topbar-dark;
  padding: 0 10px 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: $topbar-height;
  border-bottom: 1px solid $bg-topbar-dark;
  z-index: 1001;

  .topnav-menu {
    > li {
      float: left;
    }

    .nav-link {
      padding: 0 12px;
      color: rgba($white, 0.6);
      min-width: 32px;
      display: block;
      line-height: $topbar-height;
      text-align: center;
      max-height: $topbar-height;

      svg {
        height: 20px;
        width: 20px;
        color: rgba($white, 0.6);
        fill: rgba($white, 0.12);
      }

      &:hover,
      &:focus,
      &:active {
        svg {
          color: $menu-item-active;
          fill: rgba($menu-item-active, 0.1);
        }
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  /* Search */
  .app-search {
    height: $topbar-height;
    display: grid;
    width: 180px;
    margin-right: 20px;
    align-items: center;

    .react-select__placeholder,
    input::placeholder {
      font-size: 0.8125rem;
      color: rgba($white, 0.3);
      margin: 0;
    }

    .react-select__menu {
      overflow: hidden;
      width: 320px !important;
      margin-top: 0;
    }

    .react-select__input,
    .react-select__value-container {
      color: $white !important;
      padding: 0;
    }

    .form-control,
    .react-select__control {
      border: none;
      height: 38px;
      padding-left: 20px;
      padding-right: 0;
      color: $white;
      background-color: rgba($white, 0.07);
      box-shadow: none;
      // border-radius: 30px 30px 30px 30px;
    }

    .input-group-text {
      margin-left: 0 !important;
      z-index: 4;
    }

    .btn {
      // background-color: rgba($white, 0.07);
      border-color: transparent;
      color: rgba($white, 0.3);
      border-radius: 0 30px 30px 0;
      box-shadow: none !important;
    }
  }

  .button-menu-mobile {
    border: none;
    color: $white;
    display: inline-block;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }
}

/* Notification */
.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px;
  }

  .notify-item {
    padding: 12px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 5px;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-800;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }

  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.noti-icon {
  font-size: 21px;
  vertical-align: middle;
}

.profile-dropdown {
  min-width: 170px !important;

  i {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.nav-user {
  padding: 0 12px !important;

  &:hover,
  &:focus,
  &:active {
    color: $menu-item-hover !important;
    text-decoration: none;
  }

  img {
    height: 32px;
    width: 32px;
  }
}

@include media-breakpoint-down(lg) {
  .logo-box {
    padding-right: 0 !important;
    border-bottom: 1px solid $gray-300;

    .logo-lg {
      display: none !important;
    }

    .logo-sm {
      display: block !important;
    }
  }
}

@media (max-width: 600px) {
  .navbar-custom {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

// Light Topbar

body[data-topbar-color='light'] {
  .navbar-custom {
    background-color: $bg-topbar-light !important;
    border-bottom: 1px solid $gray-300;

    .topnav-menu {
      .nav-link {
        color: $dark;

        svg {
          color: $dark;
          fill: rgba($dark, 0.12);
        }

        &:hover,
        &:focus,
        &:active {
          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }
      }
    }

    .button-menu-mobile {
      color: $dark;
    }

    /* Search */
    .app-search {
      .react-select__placeholder,
      input::placeholder {
        color: $gray-500 !important;
      }

      .react-select__input,
      .react-select__value-container {
        color: $dark !important;
        padding: 0;
      }

      .form-control,
      .react-select__control {
        color: $dark !important;
        background-color: $gray-200;
        border-color: $gray-200;
      }

      .btn {
        background-color: $gray-200;
        color: $gray-400;
      }
    }
  }

  .logo-box {
    background: $bg-leftbar;
    border-right: 1px solid $gray-300;
  }

  .logo-dark {
    display: block;
  }

  .logo-light {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  body[data-layout-mode='horizontal'] {
    // Navbar Button - Horizontal
    .navbar-toggle {
      border: 0;
      position: relative;
      padding: 0;
      margin: 0;
      cursor: pointer;

      .lines {
        width: 25px;
        display: block;
        position: relative;
        height: 16px;
        transition: all 0.5s ease;
        margin-top: calc(#{$topbar-height - 16px} * 0.5);
      }

      span {
        height: 2px;
        width: 100%;
        background-color: $gray-700;
        display: block;
        margin-bottom: 5px;
        transition: transform 0.5s ease;
      }

      &.open {
        span {
          position: absolute;

          &:first-child {
            top: 7px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            visibility: hidden;
          }

          &:last-child {
            width: 100%;
            top: 7px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

body[data-layout-mode='horizontal'] {
  .button-menu-mobile {
    display: none;
  }

  .logo-box {
    width: auto;
    padding-right: 0px;
    background-color: transparent;
    padding-left: 0;
    border: none !important;
  }

  @media (max-width: 1366px) {
    .logo-box {
      padding-left: 20px;
    }
  }
}

@media (max-width: 360px) {
  .navbar-custom {
    .topnav-menu {
      .nav-link {
        padding: 0 12px;
      }
    }
    .button-menu-mobile {
      width: 45px;
    }
  }
}

body[data-layout-mode='two-column'] {
  .logo-box {
    display: none;
  }
}
