//
// accordions.scss
//

.custom-accordionwitharrow {
  .card {
    + .card {
      margin-top: 0.5rem;
    }

    .card-header {
      background-color: transparent;
    }
  }

  .accordion-arrow {
    font-size: 1.2rem;
    margin-top: -5px;
  }

  a {
    &.collapsed {
      i.accordion-arrow {
        &:before {
          content: '\e831';
        }
      }
    }
  }
}

// Custom Accordion

.data-serie-accordion {
  .not-active .card-header {
    background-color: #ffd395;
  }

  .card-header {
    text-align: left;

    @include media-breakpoint-down(sm) {
      padding: 1rem 0.5rem;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .title,
  .unit,
  .dateRange,
  .values {
    display: inline-block;
    margin-right: 10px;
  }

  .values.accordion-header {
    float: right;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .values-mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    width: 100%;
    text-align: center;
    margin-bottom: 4px;
  }

  .static-button {
    margin-right: 15px;

    @include media-breakpoint-down(sm) {
      margin: 4px 15px;
    }
  }

  .graph-buttons {
    float: right;
    padding-bottom: 1.25rem;
    display: inline-flex;

    @include media-breakpoint-down(sm) {
      display: grid;
      width: 100%;
    }
  }

  .data-serie-id {
    display: inline-block;
    padding: 10px;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    background-color: #e2e3e5;
    color: #41464b;
    font-weight: 600;
    margin-left: 15px;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
      margin: 4px 15px;
      width: -webkit-fill-available;
    }
  }

  .data-series-empty {
    font-size: large;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: medium;
      padding: 15px;
    }
  }
}

.card-body {
  @include media-breakpoint-down(sm) {
    padding: 0 !important;
  }
}

.graph-accordion {
  padding: 0;

  .card {
    border: none;
    border-bottom: 1px solid #e2e7f1;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  .card-body {
    padding: 0.25rem 0.5rem;
  }

  .apexcharts-toolbar {
    right: 15px !important;
  }
}

.header-checkbox {
  display: inline-block;
  float: left;

  // margin-right: 10px;
  input {
    margin: 0px 10px 0px 0px;
  }
}

.custom-accordion {
  .card {
    overflow: visible;
    border-color: transparent !important;
    border-left: 2px dashed $gray-300 !important;
    box-shadow: none;
    padding-left: 24px;
    margin-left: 10px;

    .card-header {
      border-radius: 7px !important;
      background-color: rgba(52, 58, 64, 0.12);

      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 22px;
        width: 25px;
        height: 2px;
        background: $card-cap-bg;
      }
    }

    .icon {
      position: absolute;
      left: -18px;
      top: -2px;
      background-color: $card-bg;
      border-radius: 50%;
      margin-right: 1px;
    }
  }
}

.custom-accordion-main {
  .card-header {
    background-color: lightgray;
    margin-bottom: 0.4rem;
  }
}

.main-acc {
  border: 1px solid lightgray !important;
}

.no-data {
  margin: 15px;
}
