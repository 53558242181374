//
// calendar.scss
//

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view-container,
.fc-view-harness {
  .table-bordered {
    th,
    td {
      border: $table-border-width solid $table-border-color;
    }
  }
  .fc-popover {
    z-index: 1000 !important;
    background-color: $gray-100;
  }
}

.fc-view {
  margin-top: 30px !important;
}

.none-border {
  .modal-footer {
    border-top: none;
  }
}

.fc-toolbar {
  margin: 10px 0 5px 0 !important;

  h2 {
    font-size: 1.25rem !important;
    line-height: 1.875rem !important;
    text-transform: uppercase;
  }
}

.fc-day-grid-event {
  .fc-time {
    font-weight: $font-weight-medium;
  }
}

th.fc-day-header,
th.fc-col-header-cell {
  padding: 0.5rem 0 !important;
}

.fc-day {
  background: transparent;
}

.fc-toolbar {
  .fc-state-active,
  .ui-state-active,
  button:focus,
  button:hover,
  .ui-state-hover {
    z-index: 0;
  }
}

.fc {
  th.fc-widget-header {
    background: $gray-300;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
  }
}

.fc-unthemed {
  th,
  td,
  thead,
  tbody,
  .fc-divider,
  .fc-row,
  .fc-popover {
    border-color: $gray-300;
  }

  td.fc-today,
  .fc-divider {
    background: $gray-300;
  }
}

.fc-button {
  background: $gray-300;
  border: none;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover,
.fc-state-highlight,
.fc-cell-overlay {
  background: $gray-300;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-unthemed {
  .fc-today {
    background: $white;
  }
}

.fc-event {
  border-radius: 2px !important;
  border: none !important;
  cursor: move;
  font-size: 0.8125rem !important;
  margin: 5px 7px;
  padding: 5px 5px !important;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: $white;
  border-radius: 4px;
}

.fc-basic-view {
  td.fc-week-number {
    span {
      padding-right: 8px;
    }
  }

  td.fc-day-number {
    padding-right: 8px;
  }

  .fc-content {
    color: $white;
  }
}

.fc-time-grid-event {
  .fc-content {
    color: $white;
  }
}

.fc-content-skeleton {
  .fc-day-top {
    .fc-day-number {
      float: right;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: $gray-100;
      border-radius: 50%;
      margin: 5px;
      font-size: 11px;
    }
  }
}

@include media-breakpoint-down(md) {
  .fc-toolbar {
    display: block !important;
    .fc-left,
    .fc-right,
    .fc-center {
      text-align: left;
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

// overriding button sizes
.fc-toolbar {
  .btn {
    @include button-size(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $btn-font-size-sm,
      $btn-border-radius-sm
    );
  }
}

.fc-list-item-title,
.fc-list-item-time {
  color: $white;
}

[dir='rtl'] .fc-toolbar {
  .btn-group {
    .btn {
      &:first-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-radius: $btn-border-radius;
      }
      &:last-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: $btn-border-radius;
      }
    }
  }
}

.fc-list-table {
  .fc-list-day,
  .fc-list-event {
    th,
    td {
      padding: $table-cell-padding-y $table-cell-padding-x !important;
    }
  }

  .fc-list-day {
    th,
    td {
      background: transparent !important;
    }
  }

  .fc-list-event {
    &:hover {
      td,
      th {
        background-color: inherit !important;
      }
    }

    td,
    th {
      color: $white !important;
    }
  }
}

.fc-daygrid-event {
  color: $white !important;
}
