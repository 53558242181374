//
// footer.scss
//

.footer {
  bottom: 0;
  padding: 19px 15px 20px;
  position: absolute;
  right: 0;
  color: $text-muted;
  left: $leftbar-width;
  border-top: 1px solid $card-border-color;

  .footer-links {
    a {
      color: $text-muted;
      margin-left: 1.5rem;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent;
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}

body[data-layout-mode='horizontal'] {
  .footer {
    left: 0 !important;
  }

  &[data-layout-width='boxed'] {
    .footer {
      max-width: $boxed-layout-width !important;
    }
  }
}

body[data-layout-mode='detached'] {
  @include media-breakpoint-up(lg) {
    .footer {
      position: inherit;
      margin: 0 10px;
    }
  }
}
