//
// right-sidebar.scss
//

.right-bar {
  background-color: $rightbar-bg;
  box-shadow:
    0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  z-index: 9999;
  float: right !important;
  right: -($rightbar-width + 10px);
  top: 0;
  bottom: 0;

  .rightbar-title {
    background-color: $primary;
    padding: 27px 25px;
    color: $white;
  }

  .right-bar-toggle {
    background-color: lighten($dark, 7%);
    height: 24px;
    width: 24px;
    line-height: 27px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      background-color: lighten($dark, 10%);
    }
  }

  .user-box {
    padding: 25px;
    text-align: center;

    .user-img {
      position: relative;
      height: 64px;
      width: 64px;
      margin: 0 auto 15px auto;

      .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0px;
        height: 24px;
        width: 24px;
        background-color: $white;
        line-height: 24px;
        border-radius: 50%;
        box-shadow: $box-shadow-lg;
      }
    }

    h5 {
      margin-bottom: 2px;

      a {
        color: $dark;
      }
    }
  }

  .notification-item {
    .media {
      padding: 0.75rem 1rem;

      &:hover {
        background-color: $dropdown-link-hover-bg;
      }
    }

    .user-status {
      position: absolute;
      right: 0px;
      bottom: -4px;
      font-size: 10px;

      &.online {
        color: $success;
      }

      &.away {
        color: $warning;
      }

      &.busy {
        color: $danger;
      }
    }
  }
}

// Rightbar overlay
.rightbar-overlay {
  background-color: rgba($dark, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.right-bar-enabled {
  .right-bar {
    right: 0;
  }

  .rightbar-overlay {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .right-bar {
    overflow: auto;

    .slimscroll-menu {
      height: auto !important;
    }
  }
}
