//
// maps.scss
//

.gmaps,
.gmaps-panaroma {
  height: 35vw;
  background: $gray-100;
  border-radius: 3px;

  @media screen and (min-width: 660px) and (max-width: 1200px) {
    height: 95vw;
  }
  @media screen and (max-width: 660px) {
    height: 125vw;
  }
}

.leaflet-popup-content .container {
  padding: 0;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.leaflet-control-attribution.leaflet-control a svg {
  display: none !important;
}

.number-popup {
  color: #008100;
  font-size: 40px;
  position: absolute;
  bottom: 0px;
  margin-left: 10px;
  font-weight: 700;
}

.title-popup {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.leaflet-popup-close-button {
  display: none;
}
