.location-header {
  width: 100%;
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: start;

  .title-source-wrapper {
    min-width: 0;

    h2 {
      margin: 0 0 0.25rem;
      font-size: clamp(1.5rem, 2vw, 2rem);
      line-height: 1.2;
    }

    .data-source {
      a {
        color: #0066cc;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      strong,
      b {
        font-weight: 600;
      }

      em,
      i {
        font-style: italic;
      }
    }
  }

  .last-measured-values {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-end;
    min-width: 260px;

    .pollution-badge {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      min-height: 80px;
      padding: 1rem;
      border-radius: 0.5rem;
      box-sizing: border-box;

      .quantity-name {
        font-weight: 600;
        font-size: 1.1rem;
        flex: 1;
        padding-right: 1rem;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .last-value {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 0.8;

        &.big-number {
          font-size: 2.2rem;
        }
      }
    }

    .last-measured-date {
      font-size: 0.95em;
      text-align: right;
      width: 100%;
      color: #666;

      span {
        display: block;
        margin-top: 0.25rem;
        font-weight: 600;
        color: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .last-measured-values {
      width: 100%;
      align-items: stretch;

      .last-measured-date {
        text-align: left;
      }

      .pollution-badge {
        .quantity-name {
          font-size: 1rem;
          -webkit-line-clamp: 1;
        }

        .last-value {
          font-size: 2.4rem;

          &.big-number {
            font-size: 2rem;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .title-source-wrapper {
      h2 {
        font-size: 1.8rem;
      }

      .data-source {
        font-size: 0.95em;
      }
    }

    .pollution-badge {
      min-height: 70px;
      padding: 0.75rem;

      .last-value {
        font-size: 2rem;

        &.big-number {
          font-size: 1.8rem;
        }
      }
    }
  }
}
